body { 
  --bs-body-font-family: 'Nunito Sans'; 
}

#root{
  display: flex;
  flex-direction: row;
  background-color: #0011ff;
}

/* ----------- iPhone 6, 6S, 7 and 8 Portrait */

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    #root{
      flex-direction: column;
    }

}

/* ----------- iPhone 6, 6S, 7 and 8 Landscape */

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) 
  and (orientation: landscape){ 
    #root{
      flex-direction: row;
    }

}

/* ----------- iPhone X ----------- */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    #root{
      height: 100%;
    }
}

/* iPhone 11 and iPhone XR */
@media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) { 
      #root{
        height: auto;
      }
}

/* iPhone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X */
@media only screen 
and (device-width: 375px) 
and (device-height: 812px) 
and (-webkit-device-pixel-ratio: 3) { }

/* iPhone 11 Pro Max and iPhone Xs Max and 7 Plus 8 Plus */
@media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 3) { }

/* iPhone 13, iPhone 12 and iPhone 12 Pro */
@media only screen 
    and (device-width: 390px) 
    and (device-height: 844px) 
    and (-webkit-device-pixel-ratio: 3) { }

/* iPhone 13 Pro Max and iPhone 12 Pro Max */
@media only screen 
    and (device-width: 428px) 
    and (device-height: 926px) 
    and (-webkit-device-pixel-ratio: 3) {
      #root{
        height: 100%;
      }
}


