.container_intro {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.portada_intro {
    width: 80vw;
}

.container_portada_intro:hover {
    animation: flip; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1.0s; /* don't forget to set a duration! */
}