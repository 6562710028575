

.item{
    position: relative;
    width: 300px;
    height: 300px;
    margin: 15px;
    padding: 1px;
    padding-bottom: 10px;
}

.image_release{
    display: block;
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
}

.info{
    position: absolute;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0; 
    bottom: 0;
    height: 300px;
    border-radius: 10px !important;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-around !important;
}

.info:hover{
    background: #0000ff9c;
    padding: 2px;
    border: 2px solid white;
}

.headerInfo {
    font-weight: 900;
    color: whitesmoke;
}

.mobile_info_release {
    display: none;
}

/*  iPhone SE 2020, 6, 6S, 7 and 8 Portrait */

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .mobile_info_release {
        width: 100%;
        display: flex;
        padding-right: 5px;
        padding-left: 5px;
        margin-top: 5px;
    }
    .mobile_headerInfo {
        font-weight: 300;
        color: whitesmoke;
        text-align: justify;
        font-weight: 900;
        text-decoration: none;
    }
}


/*  iPhone SE 2020, 6, 6S, 7 and 8 Landscape */

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) 
  and (orientation: landscape) { 
    .item{
        margin: 0;
    }
    .mobile_info_release {
        position: absolute;
        top: 0px;
        left: 300px;
        max-width: 250px;
    }
}
/* ----------- iPhone X and 6/7/8 Plus Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) {
    .mobile_info_release {
        width: 100%;
        display: flex;
        padding-right: 5px;
        padding-left: 5px;
        margin-top: 5px;
    }
    .mobile_headerInfo {
        font-weight: 300;
        color: whitesmoke;
        text-align: justify;
        font-weight: 900;
        text-decoration: none;
    }
  }

/* ----------- iPhone X and 6/7/8 Plus Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) 
  and (orientation: landscape) { 
    .item{
        margin: 0;
    }
    .mobile_info_release {
        position: absolute; 
        top: 0px;
        left: 300px;
        max-width: 250px;
    }
}