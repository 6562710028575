
.menu {
    display: flex;
    max-height: 60px;
    width: 125px;
    position: sticky;
    top: 10px;
    margin: 10px;
    justify-content: center;
}

.buton {
    align-self: center;
}

.imageButon{
	cursor: pointer;
}

.headerTitle{
    align-self: center;
    margin: 0px;
}

.contents{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}

.enlace {
    text-decoration: none;
    color: #0000ff;
}

.contact {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.offcanvas-title h5 {
    font-weight: 800;
}

.headerLink {
    font-weight: 800;
}

.headerContact {
    font-weight: 700;
    color: black;
}

.linkContact {
    text-decoration: none;
}

/*  iPhone SE 2020, 6, 6S, 7 and 8 Portrait */

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
      .menu{
          top: 20px;
          margin-top: 20px;
          margin-left: auto;
          margin-right: auto;
          z-index: 10;
      }

}

/*  iPhone SE 2020, 6, 6S, 7 and 8 Landscape */

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) 
  and (orientation: landscape) { 
      .menu{
          top: 10px;
          margin-top: 0;
          padding-left: 10px;
      }
    
}