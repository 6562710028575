.releases_page_container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    right: 10rem;
}

.releases_page {
    display: flex;
    width: 90vw;
}

/* ----------- iPhone 6, 6S, 7 and 8 Portrait */

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .releases_page {
        display: flex;
        width: 100vw;
    }
    .releases_page_container{
        row-gap: 55px;
        margin-bottom: 55px;
    }
}

/*  iPhone SE 2020, 6, 6S, 7 and 8 Landscape */

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) 
  and (orientation: landscape){ 
    .releases_page {
        margin-top: 15px;
        display: flex;
        width: 100vw;
    }
    .releases_page_container{
        row-gap: 25px;
        margin-bottom: 55px;
        justify-content: flex-start;
    }
    
}

/* ----------- iPhone X and 6/7/8 Plus Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) 
  and (orientation: landscape) { 
    .releases_page {
        margin-top: 15px;
        display: flex;
        width: 100vw;
    }
    .releases_page_container{
        flex-direction: column;
        row-gap: 25px;
        margin-bottom: 55px;
        justify-content: flex-start;
    }
}