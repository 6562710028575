.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    row-gap: 30px;
}

.logo_head{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    cursor: sw-resize;
}

#logo_links {
    width: 50%;
    align-self: center;
}

.social {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 50px;
}

.linkTree {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    row-gap: 30px;
    margin-bottom: 30px;
}

.a-tag {
    color: whitesmoke;
    text-decoration: none;
    font-weight: 400;
}

.imgLinks {
    height: 40px;
}

.a-tag:hover {
    text-decoration: overline;
    color: whitesmoke;
    cursor: ne-resize;
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        margin-top: 20px;
        row-gap: 30px;
    }
    
    .logo_head{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        cursor: sw-resize;
    }
    
    #logo_links {
        width: 90%;
        align-self: center;
        height: auto;
    }

    .imgLinks {
        height: 30px;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 

}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        margin-top: 20px;
        row-gap: 30px;
    }
    
    .logo_head{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        cursor: sw-resize;
    }
    
    #logo_links {
        width: 90%;
        align-self: center;
        height: auto;
    }

    .imgLinks {
        height: 30px;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}