.release_page {
    width: 100%;
    height: 100%;
}

.cuerpo_release{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    row-gap: 20px;
}
.link_release{
    position: relative;
    top: 0px;
    margin-left: 10px;
    margin-right: 10px;
}

.imagen_release {
    height: 100vh;
}

.tracklist_release{
    position: absolute;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    left: 0;
    right: 0;
    top: 0; 
    height: 100%;
    color: aliceblue;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-evenly !important;
}

.tracklist_release:hover{
    background: #0000ff9c;
    cursor: ne-resize;
}

.song_release{
    margin-bottom: 1px;
    margin-top: 1px;
}

.resume_release {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
    text-align: right;
    color: azure;
    padding-bottom: 8px;
    padding-top: 20px;
    margin-left: 20px;
    margin-right: 30px;
}

.resume_release_up {
    justify-self: flex-start;
    display: flex;
    flex-direction: column;
}

.resume_release_down {
    justify-self: flex-end;
    display: flex;
    flex-direction: column;
}

.mobile_tracklist_release {
    display: none;
}

.header_release{
    font-weight: 900;
}

/* 
@media only screen and (max-width: 1200px) {
    .cuerpo_release{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
        margin-top: 10px;
    }
    .imagen_release {
        width: 100%;
        height: auto;
    }
    .resume_release {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        width: 30%;
        text-align: right;
        color: azure;
        padding-bottom: 8px;
        padding-top: 20px;
        margin-left: 20px;
        margin-right: 30px;
    }
    
    .resume_release_up {
        justify-self: flex-start;
        display: flex;
        flex-direction: column;
    }
}  */

/*  iPhone SE 2020, 6, 6S, 7 and 8 Portrait */

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .cuerpo_release {
        margin-top: 10px;
    } 
    .imagen_release {
        width: 100%;
        height: auto;
    }
    .resume_release {
        height: auto;
        width: 100%;
        padding-top: 0;
        text-align: justify;
        row-gap: 15px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 10px;
    }
    .resume_release_up {
        text-align: right;
    }
    .mobile_tracklist_release {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    #mobile_header_tracklist {
        font-weight: 800;
    }
}

/*  iPhone SE 2020, 6, 6S, 7 and 8 Landscape */

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 
    .cuerpo_release{
        margin-top: 0;
          flex-direction: column;
          align-items: center;
    }
    .imagen_release {
        height: 100vh;
        width: auto;
    }
    .resume_release{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        padding-right: 10px;
    }
}

/* ----------- iPhone X and 6/7/8 Plus Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .resume_release{
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    }
    .mobile_tracklist_release {
        margin-top: 55px;
        margin-bottom: 55px;
    }
}

/*  iPhone X and 6/7/8 Plus Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) 
  and (orientation: landscape) { 
    .cuerpo_release{
        margin-top: 0;
        flex-direction: row;
        justify-content: center;
    }
    .imagen_release {
        height: 100vh;
        width: auto;
    }
    .resume_release{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        column-gap: 15px;
        row-gap: 15px;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    .mobile_tracklist_release {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .resume_release_up {
        align-self: flex-end;
    }
    .resume_release_down {
        align-self: flex-start;
        text-align: justify;
        max-width: 50%;
    }
}

/* iPhone 11 and iPhone XR Portrait */
@media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) { 
        .resume_release{
            margin-bottom: 60px;
        }
        .mobile_tracklist_release {
            margin-top: 70px;
            margin-bottom: 70px;
        }
}

/* iPhone 11 Pro Max and iPhone Xs Max Portrait */
@media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 3) { 
        .resume_release{
            margin-bottom: 20px;
        }
        .mobile_tracklist_release {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

/* iPhone 13, iPhone 12 and iPhone 12 Pro Portrait */
@media only screen 
    and (device-width: 390px) 
    and (device-height: 844px) 
    and (-webkit-device-pixel-ratio: 3) {
        .resume_release{
            margin-bottom: 55px;
        }
        .mobile_tracklist_release {
            margin-top: 60px;
            margin-bottom: 60px;
        }
    }